<template>
    <main class="container m-auto max-w-full p-5 mt-2 z-0">

        <div class="text-xs mb-5 text-center cursor-pointer"><span :class="{underline: language == 'german'}" @click="language = 'german'">Deutsch</span> | <span :class="{underline: language == 'english'}" @click="language = 'english'">English</span></div>

        <div v-if="language == 'german'">
            <div class="font-bold text-2xl mb-10 text-center">Datenschutzerklärung</div>

            <div class="text-block">
                <div class="heading">Datenschutz</div>
                Im folgenden Text erklären wir Ihnen, welche Daten wir sammeln und wie wir diese verwenden.
                Diese Datenschutzerklärung gilt ausschließlich für die ShinyTracker App. 
                Eine Datenschutzerklärung für diese Webseite finden Sie auf der Startseite von <a href="https://diafischchen.net/?page=datenschutz" target="_blank" rel="noreferrer noopener">diafischchen.net</a>.
            </div>

            <div class="text-block">
                <div class="heading">Welche Daten wir erheben</div>
                Alle Einstellungen und Nutzereingaben, die innerhalb der App getätigt werden, werden lokal auf dem Gerät abgespeichert und nicht an uns oder Dritte übermittelt.
                Wir erheben bzw. speichern keinerlei Informationen über die Nutzer unserer App. Allerdings baut die App ab und zu Verbindungen zu
                unseren Servern auf, um auf gewisse Daten zuzugreifen, die innerhalb der App benötigt werden.
                Bei diesen Verbindungen handelt es sich aber, wie erwähnt, um unsere Web-Dienste, welche der 
                <a href="https://diafischchen.net/?page=datenschutz" target="_blank" rel="noreferrer noopener">Datenschutzerklärung von diafischchen.net</a> unterliegen.
            </div>

            <div class="text-block">
                <div class="heading">Links zu anderen Webseiten</div>
                Einige Links in unserer App verweisen auf andere Webseiten, welche nicht von uns betrieben werden.
                Wir übernehmen keine Haftung für den Inhalt oder die Datenschutzbestimmungen dieser Drittanbieter Webseiten.
            </div>

            <div class="text-block">
                <div class="heading">Kontakt</div>
                E-Mail: shinytracker@diafischchen.net
            </div>
        </div>

        <div v-if="language == 'english'">
            <div class="font-bold text-2xl mb-10 text-center">Privacy Policy</div>

            <div class="text-block">
                <div class="heading">Privacy</div>
                In the following text, we describe what data we collect and how we use this data.
                This privacy policy is only directed to the ShinyTracker App itself.
                You can find the privacy policy for this website on the homepage of <a href="https://diafischchen.net/?page=datenschutz" target="_blank" rel="noreferrer noopener">diafischchen.net</a>.
            </div>

            <div class="text-block">
                <div class="heading">What data do we collect</div>
                All settings and user inputs in the App are saved locally on the Device itself and will not be sent to us or third party services.
                We dont collect or save any data from the users of our app. however sometimes the app sets up a connection to our servers
                to fetch certain information from our servers that are needed by the app to work propperly.
                Any Connection to our Server are subject to the <a href="https://diafischchen.net/?page=datenschutz" target="_blank" rel="noreferrer noopener">privacy policy of diafischchen.net</a>.
            </div>

            <div class="text-block">
                <div class="heading">Links to third party Websites</div>
                Some links in our app are refering to third party websites.
                We assume no responsibility for the content or privacy policies of any third party websites or services.
            </div>

            <div class="text-block">
                <div class="heading">Contact</div>
                E-Mail: shinytracker@diafischchen.net
            </div>
        </div>
    </main>
</template>

<script>

export default {

    name: 'Privacy',
    data() {
        return {
            language: 'german'
        }
    }

}

</script>

<style scoped>

.container {
    width: 1200px;
}

.text-block {
    @apply mb-10 text-left;
}

.text-block .heading {
    @apply text-2xl font-semibold mb-3;
}

.text-block a {
    @apply text-primary underline;
}

</style>